import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import OpenTable, { positions } from 'react-opentable'

function Cards() {
  return (
    <div className='cards'>
      <h1>Start reservation now!</h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
        <BrowserView>
          <OpenTable
            rid='123123'
            theme='wide'
          />
        </BrowserView>
        <MobileView>
          <OpenTable
            rid='123123'
            theme='standard'
          />
        </MobileView>        
      </div>      
      <h1>Check out these EPIC Destinations!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-9.jpg'
              text='Explore the hidden waterfall deep inside the Amazon Jungle'
              label='Adventure'
              path='/services'
            />
            <CardItem
              src='images/img-2.jpg'
              text='Travel through the Islands of Bali in a Private Cruise'
              label='Luxury'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.jpg'
              text='Set Sail in the Atlantic Ocean visiting Uncharted Waters'
              label='Mystery'
              path='/services'
            />
            <CardItem
              src='images/img-4.jpg'
              text='Experience Football on Top of the Himilayan Mountains'
              label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Ride through the Sahara Desert on a guided camel tour'
              label='Adrenaline'
              path='/sign-up'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
